import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"

export default class MailChimpForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      FNAME: null,
      EMAIL: null,
      PHONE: null,
      SALUTATION: null,
      PROPERTY: null,
      NOTES: null,
      OPTIN: "No",
      switched: props.checked || false,
      result: null,
      send: null,
      message: null,
    }
  }

  _handleSubmit = async (event) => {
    event.preventDefault()
    const result = await addToMailchimp(
      this.state.EMAIL,
      {
        ...this.state,
      },
      "https://sweetcombecottages.us7.list-manage.com/subscribe/post?u=e46665c00d318064d8502a870&amp;id=fda4e7b355"
    )
    this.setState({ result: result, send: result.result, message: result.msg })
  }

  componentWillMount() {
    const { checked } = this.props
    if (checked) {
      this.setState({
        switched: true,
      })
    }
  }

  handleChange = (event) => {
    this.setState({ [`${event.target.name}`]: event.target.value })
  }

  handleChange2 = () => {
    this.setState({
      switched: !this.state.switched,
      OPTIN: !this.state.switched ? "Newsletter" : "No",
    })
  }

  render() {
    const statee = this.state

    function validation() {
      if (statee.send === "success") {
        return (
          <div className="vali">
            Thanks for reaching out to us, we will be in contact shortly.
          </div>
        )
      } else if (statee.send === "error") {
        return (
          <div
            className="vali"
            dangerouslySetInnerHTML={{ __html: statee.message }}
          />
        )
      }
    }

    //console.log(statee)

    const { switched } = this.state

    return (
      <form onSubmit={this._handleSubmit} className="contactForm">
        <div className="title">
          <TextField
            required
            id="outlined-title-input"
            label="Title"
            type="text"
            name="SALUTATION"
            variant="outlined"
            fullWidth
            onChange={this.handleChange}
          />
        </div>
        <div className="name">
          <TextField
            required
            id="outlined-name-input"
            label="Name"
            type="text"
            name="FNAME"
            variant="outlined"
            fullWidth
            onChange={this.handleChange}
          />
        </div>
        <div className="email">
          <TextField
            required
            id="outlined-email-input"
            label="Email"
            type="email"
            name="EMAIL"
            variant="outlined"
            fullWidth
            onChange={this.handleChange}
          />
        </div>
        <div className="phone">
          <TextField
            id="outlined-phone-input"
            label="Phone"
            type="number"
            name="PHONE"
            variant="outlined"
            fullWidth
            onChange={this.handleChange}
          />
        </div>
        <div className="property">
          <TextField
            id="outlined-property-input"
            label="Property Name/Ref"
            type="text"
            name="PROPERTY"
            variant="outlined"
            fullWidth
            onChange={this.handleChange}
          />
        </div>
        <div className="notes">
          <TextField
            multiline
            rows={4}
            rowsMax={10}
            fullWidth
            required
            id="outlined-notes-input"
            label="Message"
            type="text"
            name="NOTES"
            variant="outlined"
            onChange={this.handleChange}
          />
        </div>
        <div className="check">
          <Checkbox
            {...this.props}
            checked={switched}
            onChange={this.handleChange2}
          />
          <span>
            Please tick to confirm you are happy to receive emails from
            Sweetcombe Holiday Cottages. Our{" "}
            <a title="Privacy Policy" target="_blank" href="/privacy-policy/">
              Privacy policy
            </a>{" "}
            explains how we use your information.
          </span>
        </div>
        <div className="subbers">
          <button label="Submit" type="submit">
            Submit
          </button>
          {validation()}
        </div>
      </form>
    )
  }
}
