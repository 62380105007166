import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components"
import { Link } from "gatsby"

const Wrapper = styled.div`
    position: absolute;
    top: 10%;
    right:5%;
    z-index: 5;

    img{
        width:40vw;
        @media ${(props) => props.theme.mq.xs} {
            width:25vw;
        }
    }

`

const Highlight = ({ content }) => {

    const link = content?.highlightLink?.url

    const image = content?.highlightImage?.sourceUrl

    return (
        <Wrapper>
            <Link to={link}>
                <img src={image} />
            </Link>
        </Wrapper> 
    )
}

Highlight.propTypes = {
    content: PropTypes.object,
}

export default Highlight