import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import MailChimpForm from "./mailChimpForm"
import Heading from "../../utils/heading"
import Icon from "../../utils/icon"

const Wrapper = styled.div.attrs({
  className: "container",
})`
  ${(props) => props.theme.wrappersContainers.primaryContainer};
  overflow: auto;

  .inner {
    ${(props) => props.theme.wrappersContainers.primaryWrapper};

    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "form"
      "details";

    @media ${(props) => props.theme.mq.xs} {
      grid-gap: 1rem 5rem;
      grid-template-columns: 3fr 2fr;
      grid-template-rows: auto;
      grid-template-areas: "form details";
    }

    .form {
      grid-area: form;
    }

    .details {
      grid-area: details;

      a {
        text-decoration: none;
      }

      .svgWrapper {
        float: left;
        margin-right: 1rem;
        margin-top: 3px;
        fill: ${(props) => props.theme.colors.primary};
      }
    }

    .contactForm {
      padding: 1rem 0;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 11fr;
      grid-template-rows: repeat(7, auto);
      grid-template-areas:
        "title name"
        "email email"
        "phone phone"
        "property property"
        "notes notes"
        "check check"
        "sub sub";

      @media ${(props) => props.theme.mq.xs} {
        grid-gap: 1rem 3rem;
      }

      .title {
        grid-area: title;

        .MuiOutlinedInput-root {
          min-width: 100px;
        }
      }

      .name {
        grid-area: name;
      }

      .email {
        grid-area: email;
      }

      .phone {
        grid-area: phone;
      }

      .property {
        grid-area: property;
      }

      .notes {
        grid-area: notes;
      }

      .check {
        grid-area: check;
        display: flex;
        align-items: center;
      }

      .subbers {
        grid-area: sub;
        display: flex;
        align-items: center;
        flex-direction: column;
        @media ${(props) => props.theme.mq.xs} {
          flex-direction: row;
        }

        .vali {
          margin-top: 1rem;
          @media ${(props) => props.theme.mq.xs} {
            margin-top: 0;
            margin-left: 1rem;
          }
        }

        button {
          ${(props) => props.theme.buttons.primary};
          width: 100%;
          @media ${(props) => props.theme.mq.xs} {
            width: fit-content;
          }
        }
      }

    }
  }
`

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        companyDetails {
          companyDetails {
            companyemail
            companyphonenumber
            companyname
            companyaddress
          }
        }
      }
    }
  `)

  const compDeets = data.wp.companyDetails.companyDetails

  return (
    <Wrapper>
      <div className="inner">
        <div className="form">
          <Heading
            type="title"
            as="h3"
            color={(props) => props.theme.colors.secondary}
          >
            Send us an enquiry
          </Heading>
          <MailChimpForm />
        </div>

        <div className="details">
          <Heading
            type="title"
            as="h3"
            color={(props) => props.theme.colors.secondary}
          >
            Our contact details
          </Heading>
          <Icon name="email" height="16px" />
          <a href={"mailto:" + compDeets.companyemail} title="Email">
            {compDeets.companyemail}
          </a>
          <br />
          <br />
          <Icon name="phone" height="16px" />
          <a href={"tel:" + compDeets.companyphonenumber} title="Call">
            {compDeets.companyphonenumber}
          </a>
          <br />
          <br />
          <Icon name="home" height="16px" />
          {compDeets.companyname}
          <br />
          <br />
          {compDeets.companyaddress}
          <br />
          <br />
          Opening Hours: Monday – Friday 9am – 5pm
        </div>
      </div>
    </Wrapper>
  )
}

export default ContactPage
