import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../utils/seo"
import StructuredData from "../utils/structuredData"
import Hero from "../components/hero/hero"
import BlogRoll from "../components/blogRoll/blogRoll"
import CMS from "../utils/cms"

import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import ContactPage from "../components/contactPage/contactPage"

const Wrapper = styled.div`
  ${(props) => props.theme.wrappersContainers.primaryWrapper};
  ${(props) => props.theme.wrappersContainers.primaryContainer};
`

const faqData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Can you help us find the right property for our specific requirements?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We want all our guests to find the perfect property for an enjoyable holiday. If you need any help in making your choice, please do telephone or e-mail the office outlining your specific needs and we will be happy to guide you towards places that are suitable and away from ones that aren’t."
      }
    },{
      "@type": "Question",
      "name": "How can I pay my balance and do you send reminders?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The balance will automatically be taken from the card you paid your deposit with on the agreed date."
      }
    },{
      "@type": "Question",
      "name": "Are bed linen and towels included?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "All of our properties include bed linen and all the beds at the property will be made up for your arrival. The majority of our properties now include towels. There is one set provided per person. A set consists of a bath towel and a hand towel. Please do bring your own beach towels. By reading through the facts & features at the bottom of each property listing you will be able to see whether towels are included at your chosen property."
      }
    },{
      "@type": "Question",
      "name": "If we are staying at one of your properties for over a week is a mid- stay linen change and clean offered?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We do offer a mid-stay linen change free of charge if you are staying for longer than a week. This will be done on the middle Friday. We ask you to leave the dirty laundry by the front door between 10am – 12pm. The dirty laundry will be collected and the clean laundry will be delivered. Please leave the keys in the key safe so we can swop the laundry. We can offer a mid-stay clean, however, this will be charged for. You can request this by emailing or phoning the office and we can discuss the best way to arrange this. ***please note we are unable to offer mid stay cleans throughout the COVID 19 pandemic***"
      }
    },{
      "@type": "Question",
      "name": "Can we change the names of the people in our party after we have booked our holiday?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The person who agrees to the booking terms and conditions at the time of booking is considered the party representative and as such is responsible for all other persons, he/she brings into the property. It remains that person’s responsibility to see that all guests treat the property with the respect it is due and to ensure that at no given time do the numbers staying overnight at the property exceed the number for which the property is advertised."
      }
    },{
      "@type": "Question",
      "name": "Do the properties have central heating?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, all are properties have fully functioning heating systems and will be on before your arrival unless the weather is particularly warm."
      }
    },{
      "@type": "Question",
      "name": "Can I bring my dog with me?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pets are allowed at some of the properties and the details of the number and breed should be mentioned when booking. However, we take no responsibility for their safety. Please ensure that pets remain on the ground floor at all times, do not sit on the furniture and are never exercised on the premises. We take great exception to clearing away dog foul! Pets must never be left unattended in the property. Under the essential information for each property we specify whether or not pets are permitted."
      }
    },{
      "@type": "Question",
      "name": "Do we need to clean the property before we leave?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Owners or housekeepers clean all properties between lets, but please leave your rental property clean & tidy, otherwise the owner or caretaker may have a terrible struggle to have it thoroughly clean for the next guests. By accepting our booking terms and conditions you have agreed to leave the property at the end of your stay in the condition as found on arrival."
      }
    },{
      "@type": "Question",
      "name": "Are all your properties non-smoking?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, without exception."
      }
    },{
      "@type": "Question",
      "name": "What if we’re not happy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "If you find anything wrong please tell us within 24 hours. Our office is manned during normal working hours Monday to Friday and on Saturday mornings during busy periods. Outside of these hours our emergency phone number is always available to you. If you have difficulties or concerns regarding your holiday cottage please do contact us as soon as possible so we can ensure you have a wonderful holiday. This will enable us to resolve any problems that may arise."
      }
    },{
      "@type": "Question",
      "name": "What’s provided?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We provide a starter pack including the following: Loo roll; Jay cloth; Sponge; Washing up liquid; Hand soap; Dishwasher tablets; Tea towels. We would like to think we have thought of just about everything and perhaps that little bit more to make your holiday home, 'home from home'. We would be happy to send you the inventory which our owners adhere to when equipping each cottage. However, we assure you it is extensive and everything we would expect to find ourselves will be there to complete your holiday."
      }
    },{
      "@type": "Question",
      "name": "Where do we find the keys?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Arrangements regarding keys, access to the property and route directions are sent out to you 3 weeks before your holiday. We do not offer second sets of keys due to so many going missing in the past. We do ask that you use the key safe throughout your stay."
      }
    },{
      "@type": "Question",
      "name": "What are the arrival and departure times?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "These are given on your final receipt. 16.00pm on your arrival day with a departure time at 10.00am on your final morning. Please try not to arrive before the stated time as this can cause difficulties on busy changeover days."
      }
    },{
      "@type": "Question",
      "name": "Are stair gates, cots and high chairs provided?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "These are available in many properties. Please check individual property details or ask at the time of booking. Please note that the cots supplied are travel cots & cot linen is not included. If the property you are interested in does not have child amenities available, we can hire out travel cots & high chairs  for £15 per item. Unfortunately we don’t higher out stair gates but many properties do provide them."
      }
    },{
      "@type": "Question",
      "name": "What happens to lost Property?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A charge will be made for the return of lost property which is sent by Recorded Delivery. Whilst we make all reasonable efforts to return items reported to us by clients after departure, we take no responsibility for lost property that subsequently cannot be found. There is a minimum charge of £5."
      }
    },{
      "@type": "Question",
      "name": "Is there anything else we should be prepared for?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "·Food shopping: Many supermarkets provide delivery services to even quite rural areas which can be handy. Please also support local shops and, if possible, buy locally produced food. Walking and footpaths: many beautiful places in the East Devon area not accessible by car and are via footpaths, so bring a pair of walking boots or your wellies. If you want to walk to the pub, remember to bring a torch. Roads in East Devon: away from the main ones, roads are often narrow. So are village streets. Take care and be prepared and willing to reverse to a passing place if your car is the nearest to one. Country roads are rarely lit by artificial light, frequently have high banks and can be muddy after wet weather. Farms and estates: many farmers welcome you on their land, securing gates and keeping pets under control is a must. Some of our older houses and cottages in our stunning area are perhaps a little closer to nature than modern houses. That means spiders, daddy-long-legs and other non-harmful insects like living there too. Despite our best efforts on changeover days to ‘evict’ our extra guests, they may return given a chance! It also means that if there has been wet or humid weather and the heating has not been on slight signs of damp may appear, not serious or dangerous. Putting the heating on and leaving a few windows open will help."
      }
    }]
}

const Page = ({ data }) => {
  return (
    <>
      <SEO
        title={data.wpPage.seo.title || data.wpPage.title}
        description={
          data.wpPage.seo.metaDesc ||
          data.wpPage.excerpt ||
          data.wpPage.description
        }
        image={data?.featuredImage?.node.uri}
        canonical={data.wpPage.uri}
        article={false}
      />

      {data.wpPage.title === "FAQs" && <StructuredData dataObject={faqData} />}

      <Breadcrumbs currentPage={data.wpPage.title} />

      <PageInner page={data.wpPage} properties={data.bookingGraph} />
    </>
  )
}

export default Page

const PageInner = ({ page }) => {
  if (page.title === "Blog") {
    return (
      <>
        <BlogRoll title={page.title} sticky={true} />
      </>
    )
  } else if (page.title === "Contact Us") {
    return (
      <>
        {page.featuredImage && (
          <Hero image={page?.featuredImage?.node.localFile.childImageSharp.fluid} />
        )}

        {page.content && (
          <Wrapper>
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          </Wrapper>
        )}

        <CMS>{page.content_module_system.modules}</CMS>

        <ContactPage />
      </>
    )
  } else {
    return (
      <>
        {page.featuredImage && (
          <Hero image={page?.featuredImage?.node.localFile.childImageSharp.fluid} />
        )}

        {page.content && (
          <Wrapper>
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          </Wrapper>
        )}

        <CMS>{page.content_module_system.modules}</CMS>
      </>
    )
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      ...pageFragments
      content_module_system {
        modules {
          ...headerBlockFragmentsPage
          ...contentFragmentsPage
          ...sideBySideFragmentsPage
          ...navigationSliderFragmentsPage
          ...propSelectFragmentsPage
        }
      }
    }
  }
`
