import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Highlight from "./highlight"

const HeroWrapper = styled.div`
    position: relative;
    min-height:20rem;
    height:calc(100vh - 108px);
    @media ${props => props.theme.mq.sm} {
        min-height:30rem;
        height:100%;
    }
    @media ${props => props.theme.mq.md} {
        height:calc(100vh - 198px);
    }
`

const ImgWrapper = styled.div`
    height:100%;
    z-index:1;
    .gatsby-image-wrapper{
        min-height:20rem;
        @media ${props => props.theme.mq.sm} {
            min-height:30rem;
        }
    }
`

const Hero = ({ image, highlight, children }) => {

    return (

        <HeroWrapper>

            {highlight &&
                <Highlight content={highlight} />
            }

            {children}
            <ImgWrapper>
                <Img fluid={image} />
            </ImgWrapper>

        </HeroWrapper>
    )

}

export default Hero