import React from "react"
import { Helmet } from "react-helmet"

const StructuredData = ({ dataObject }) => (

    //   const twitterUsername = twitterUsernameFunction()

    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(dataObject)}
            </script>
        </Helmet>
    </>
)

export default StructuredData
